import React from "react";
import { useTranslation } from "react-i18next";
import '../scss/hwch.scss'
// import { DonateModail } from "./modail";

export function HWCH(){
    const { t } = useTranslation();
    const modail = t('how-its-work.modail', { returnObjects: true });
    return(
        <div className="how-its-worck">
            <div className="how-its-worck_title">
                <span className="title">{t('how-its-work.title')}</span>
                <span className="sub-title">{t('how-its-work.sub-title')}</span>
            </div>
            <div className="how-its-worck_info">
                {modail.map((el)=>{
                    
                    return(
                        <div className="hwch-info" key={el.id}>
                            <div className="hwch-info_info">
                                <span className="title-och">{el.title}</span>
                                <span className="info-och">{el.info}</span>
                            </div>
                            <div className="hwch-info_counter">
                                <span className="count">{parseInt(el.id) + 1}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* <DonateModail></DonateModail> */}
        </div>
    )
}

