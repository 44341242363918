import React, { useState } from "react";
import logoL  from "../img/logo.svg"

export function Loade(){
    const [loade,setLoade] = useState(false)
    useState(()=>{
        window.onload = setLoade(true)
    })

    return(
        <div className={loade? "loade__des" : "loade"}>
            
            <div className="loade_bar">
                <div></div>
            </div>
            <img src={logoL} alt="" />
        </div>
    )
}