import React, { useEffect, useState, useRef} from "react"
import Marquee from 'react-fast-marquee'
import '../scss/modail.scss'
import LApplePay from '../img/modail/logos_apple-pay.svg'
import LGooglePay from '../img/modail/logos_google-pay.svg'
import GooglePlay from "../img/modail/downGoogle.jpg"
import ApplePlay from "../img/modail/downApple.jpg"
import Arrow from "../img/modail/Arrow.svg"
import ArrowUp from "../img/up-arrow.svg"
import { useTranslation } from "react-i18next"



//=======logo====================
import med from "../img/partner/med.png"
import mk from "../img/partner/mk.png"
import bodro from "../img/partner/bodro.png"
import zdorovajasemyja from "../img/partner/zdorovaja-semyja.png"
import Stripe from "../img/partner/2560px-Stripe_Logo,_revised_2016.svg.png"
import ipay from "../img/partner/logo-color.svg"
import uef from "../img/partner/logoeng.png"
import ucf from "../img/partner/ucf_logo_ua_full_color.svg"
// import Liki24 from "../img/partner/liki24.png"
// import Volia from "../img/partner/Volia.png"
// import Data from "../img/partner/DATA_Logotype.png"
// import Eva from "../img/partner/logo_EVA_2019.png"


const arrayLogo = [med,mk,bodro,zdorovajasemyja,Stripe,ipay,uef,ucf]

export function CarouselPartner() {
    return(
        <div id="partners" className="carousel-partner">
                <Marquee speed={50} gradient={false}>
                    {arrayLogo.map((img, idx) => {
                        return <img className="carousel-partner_image" key={idx} src={img} alt="" srcSet="" />
                    })}
                </Marquee>
        </div>
    )
}

export function DownloadModail(){
    const { t } = useTranslation();

    return(
        <div className="modail-download">
            <div className="modail-backgound"></div>
            <span>{t('modail-download')}</span>
            <div className="modail-download_button-download">
                
                <a href="https://play.google.com/store/apps/details?id=com.magnetto.CharityHero&hl=ru&gl=US">
                    <img className="modail-download_image" src={GooglePlay} alt="" />
                </a>
                <a href="https://apps.apple.com/us/app/charity-hero/id1612487637">
                    <img className="modail-download_image" src={ApplePlay} alt="" />
                </a>
            </div>
        </div>
    )
}



export function DonateModail(){

    
    
    return(
        <div className='donation-modail'>
            <div className="modail-backgound"></div>
            <span>Хочу підтримати</span>
            <div className='donation-modail_store'>
                <img src={Arrow} alt="" className="arrow"/>
                <img src={LGooglePay} alt="" />
                <img src={LApplePay} alt="" />
            </div>
        </div>
    )
}

export function ButtonMakeADeposit(props){
    return (
        <a className='button-donation button-donation__header'
            href={props.location === "ua" ? "https://bodro-donate.charityhero.live/" : "https://bodro-donate-eu.charityhero.live/"}>
            {props.children}
        </a>
    )
}

export function DonloadAppModail(){
    return(
        <div>
            <span></span>
            <div>
                <img src="" alt="" />
                <img src="" alt="" />
            </div>
        </div>
    )
}

export function ButtonUp(){
    const [act, stateAct] = useState(false)
    const [top, stateTop] = useState(0);
    const [scroll, stateScroll] = useState(0)

    useEffect(()=>{

        document.addEventListener("scroll", ()=>{
            
            stateTop(window.pageYOffset)
            stateScroll(window.pageYOffset - top)

            if(top >= 1000){
                if(scroll >= 0){
                    stateAct(false)
                }else if(scroll <= 0){
                    stateAct(true)
                }
            }else if(top <= 1000){
                stateAct(false)
            }
        })

        
    })

    return(
        <button className={act? "button-up" : "button-up button-up__dis"} id="header">
            <img src={ArrowUp} alt="" />
        </button>
    )
}