import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './scripts/app.jsx'

import './i18n';


const root = ReactDOM.createRoot(document.querySelector('.web-site'));
root.render(
  <React.StrictMode>
    <App></App>
  </React.StrictMode>
);



