import React from "react";
import { useTranslation } from "react-i18next";
import "../scss/contactas.scss"
import ContactForm from "./components/contact-form";

export function ContactAs(){
    const { t } = useTranslation();

    return(
        <div id="contacts" className="contact-as">
            <span className="contact-as_title title">{t('contact-as.title')}</span>
            <ContactForm/>
        </div>
    )
}

