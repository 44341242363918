import React from "react"
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const SERVICE_ID = "service_hsf6wvm";
const TEMPLATE_ID = "template_lt7bai9";
const USER_ID = "p7vN9Qa1lj1P22xdI";

function ContactForm() {
    const { t } = useTranslation();
    const { register, handleSubmit, formState: {errors} } = useForm();

    const sendEmail = (formData, e) => {
        const feedbackData = {
            from_name: formData.name,
            message: formData.message,
            reply_to: formData.email,
        }

        emailjs.send(SERVICE_ID, TEMPLATE_ID, feedbackData, USER_ID)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset();
    };

    return(
        <form id="form" onSubmit={handleSubmit(sendEmail)}>
            <div className="modail-contact">
                <div className="modail-backgound"></div>
                <div className="modail-contact_info">
                    <div className="modail-contact_input-wrapper">
                        <input
                            {...register("name", {
                                required: "Please enter your name",
                                minLength: {
                                    value: 3,
                                    message: "Please enter a name with more than 3 characters"
                                }
                            })}
                            type="text"
                            className={errors.name ? "name-input error" : "name-input"}
                            id="name"
                            placeholder={t('contact-as.modail.input-name')}/>
                        <span className="modail-contact_error">{errors.name && errors.name.message}</span>
                    </div>
                    <div className="modail-contact_input-wrapper">
                        <input
                            {...register("email", {
                                required: "Please enter an email",
                                pattern: {
                                    value: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                            type="email"
                            className={errors.name ? "email-input error" : "email-input"}
                            id="email"
                            placeholder={t('contact-as.modail.input-email')}/>
                        <span className="modail-contact_error">{errors.email && errors.email.message}</span>
                    </div>
                </div>
                <input {...register("subject")} className="modail-contact_subject" id="subject" type="text" placeholder={t('contact-as.modail.imput-subject')}/>
                <textarea {...register("message")} className="modail-contact_message" placeholder={t('contact-as.modail.message')} id="message" cols="30" rows="10"></textarea>
                <div className="modail-contact_button">
                    <button className="button-donation" id="contactButton" >{t('contact-as.modail.button')}</button>
                </div>
            </div>
        </form>
    )
}

export default ContactForm;