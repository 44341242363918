import React from "react";
import "../scss/uaNeedsNow.scss"
import desktopNeedua from "../img/uaneedsnow/need-now-ua.png"
import desktopNeeden from "../img/uaneedsnow/need-now-en.png"
import desktopNeedes from "../img/uaneedsnow/need-now-es.png"
import mobileNeedua from "../img/uaneedsnow/need-now-ua-mob.png"
import mobileNeeden from "../img/uaneedsnow/need-now-en-mob.png"
import mobileNeedes from "../img/uaneedsnow/need-now-es-mob.png"
import HFOfUaImg from "../img/uaneedsnow/HFOfUaImg.png"
import { DownloadModail } from "./modail";
import { useTranslation, Trans } from "react-i18next";

const desktopImg = (lng) => {
    switch (lng) {
        case "uk":
            return desktopNeedua;
        case "en":
            return desktopNeeden;
        case "es":
            return desktopNeedes;
        default:
            return desktopNeeden;
    }
}

const mobileImg = (lng) => {
    switch (lng) {
        case "uk":
            return mobileNeedua;
        case "en":
            return mobileNeeden;
        case "es":
            return mobileNeedes;
        default:
            return mobileNeeden;
    }
}

export function NNUa(){
    const { t, i18n } = useTranslation();

    return(
        <div id="needs-for-help" className="nnua">
            <div className="nnua_title title">{t('nnua.title')}</div>
            <picture>
                <source srcSet={mobileImg(i18n.resolvedLanguage)} media="(max-width: 620px)"/>
                <img src={desktopImg(i18n.resolvedLanguage)} alt="" className="nnua_img"/>
            </picture>
        </div>
    )
}

export function ListItemHelpUa(props) {
    return <li>{props.value}</li>
}

export function HelpFofUa(){
    const { t } = useTranslation();
    const info = t('help-f-of-ua.modail.info', { returnObjects: true });

    const listItems = info.map((elem) => <ListItemHelpUa key={elem.id} value={elem.info}/>)
    console.log(listItems);
    return(
        <div className="help-f-of-ua">
            
            <div className="help-f-of-ua_title">
                <span className="title">{t('help-f-of-ua.title')}</span>
                <span className="sub-title">{t('help-f-of-ua.sub-title')}</span>
            </div>
            
            <div className="help-f-of-ua_modail modail-need-a-of-f">
                <div className="modail-backgound">
                    
                </div>
                <div className="modail-need-a-of-f_title">
                    <span className="title">
                        <Trans i18nKey="help-f-of-ua.modail.title">
                            Сьогодні українській <br/>армії необхідні кошти на:
                        </Trans>
                    </span>
                </div>
                <div className="modail-need-a-of-f_table">
                    <img src="" alt="" />
                    <div className="list list__border">
                        <ul>
                            {listItems}
                        </ul>
                    </div>
                </div>
                <div className="modail-need-a-of-f_img">
                    <img src={HFOfUaImg} alt=""/>
                </div>
            </div>
            <span>
                <Trans i18nKey="help-f-of-ua.title1">
                    Хочете допомагати на <br/>постійній основі?
                </Trans>
            </span>
            <DownloadModail></DownloadModail>
        </div>
    )
}